/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEdit,
} from "@fortawesome/free-solid-svg-icons"

import { clearLoading } from "./../actions"

import Layout from "../components/layout"
import SEO from "./../components/seo"
import Login from "./../components/login"

export const ConnexionPage = () => {
  const identifiants = useSelector(s => s.identifiants)
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(clearLoading(true, ""))
  }, [dispatch])

  if (typeof window !== "undefined" && identifiants.identifiants !== null) {
    const redirect = new URLSearchParams(window.location.search).get("redirect")
    if (redirect === null) {
      navigate(`/apps/dashboard/`)
    } else {
      navigate(redirect)
    }
  }

  return (
    <>
      <Layout banner={false}>
        <SEO title="Connexion à votre espace personnel" description="Accédez à vos commandes agrobioconso, réglez en ligne votre panier ou votre cotisation à l'association" />
        <div className="hero pattern-zigzag-light" style={{marginTop: -4}}>
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title is-2 has-text-centered dreamland">Accedez a votre espace personnel</h1>
            </div>
          </div>
        </div>
        <div className="container is-fluid pt-5 pb-5 pattern-cross-dots">
          <div className="columns is-centered login is-vcentered">
            <div className="column is-half">
              <div className="box has-text-centered" style={{background: "#e3ff96", paddingTop: "2rem", paddingBottom: "3rem"}}>
                <div className="subtitle is-2 almond" style={{background: "#ffd946", borderRadius: "5px"}}>Nouvel adhérent&nbsp;?</div>
                  <Link to="/inscription" className="button is-primary is-large dreamland" style={{ fontSize: "2rem", background: "#4d9f16", whiteSpace: "normal", lineHeight: "2rem", height: "auto"}} >
                    <FontAwesomeIcon icon={faEdit} className="mr-2" /> Creer mon compte
                  </Link>
              </div>
            </div>
            <div className="section column is-half couleurShadow">
              <div className="box has-text-centered" style={{background: "#e3ff96"}}>
                <div className="subtitle is-2 almond" style={{background: "#ffd946", borderRadius: "5px"}}>Déjà inscrit&nbsp;?</div>
                  <Login large={true} />
                </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ConnexionPage
